import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../../store/reducers/fetch-dux'
import Select from "../../Shared/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { joinClasses } from "../../../../utils";
import numeral from 'numeral';

import SilveroakLink from '../../../../utils/SilveroakLink';
import TwomeyLink from '../../../../utils/TwomeyLink';

const formatMoney = p => numeral(p || 0).format('$0,0.00');

const CartItem = ({
  classes,
  shoppingCartItems,
  handleRemoveItem,
  handleAddItem,
  putCartItem,
  inProgress,
  clearPostPromoCode,
  deleteCartItemInProgress
}) => {

  const renderSelectAmount = (total) => {
    const menuItems = [];
    for (let i = 1; i < total + 1; i++) {
      menuItems.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      );
    }
    return menuItems.map(menuItem => menuItem)
  }

  return shoppingCartItems.map((item, index) => {

    const {
      brandingId,
      unitPrice,
      kitMarkdown,
      qty,
      shoppingCartItemId,
      skuDescription,
      discount,
      productHref,
      cartLimit,
    } = item || {}

    const onRemoveItem = e => {
      e.preventDefault();
      handleRemoveItem(item);
    };
    const handleQtyChange = e => {
      const { value } = e.target || {};
      clearPostPromoCode();
      handleAddItem(item, qty, value);
      putCartItem({
        id: shoppingCartItemId,
        payload: { qty: parseInt(value) }
      });
    };


    return (
      <tr className={joinClasses(classes.itemContainer, classes.withBorder)} key={index}>
        <td className={classes.productTitle}>
          <p>
            {brandingId === 1 && (
              <SilveroakLink href={productHref}>
                <a>{skuDescription}</a>
              </SilveroakLink>
            )}
            {brandingId === 2 && (
              <TwomeyLink href={productHref}>
                <a>{skuDescription}</a>
              </TwomeyLink>
            )}
          </p>
          {!inProgress && !deleteCartItemInProgress &&
            <span className={classes.link} disabled={inProgress} onClick={onRemoveItem}>Remove</span>
          }
          {(inProgress || deleteCartItemInProgress) &&
            <span className={classes.grey}>Updating Quantity... </span>
          }
        </td>
        <td className={classes.productQty}>
          <Select disabled={inProgress} value={qty} onChange={handleQtyChange} style={{ marginRight: '20px' }}>
            {renderSelectAmount(cartLimit)}
          </Select>
        </td>
        <td className={classes.productPrice}>
          <div>{formatMoney((unitPrice - (kitMarkdown || 0)) * qty)}</div>
          {discount > 0 && (
            <div style={{ color: 'red' }}>-{formatMoney(discount)}</div>
          )}
        </td>
      </tr>
    )
  });
};

const mapState = state => {

  const {
    inProgress
  } = state.fetchReducer.putCartItem.toJS();

  return { inProgress };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      putCartItem: fetchDux.putCartItem.createAction,
      clearPostPromoCode: fetchDux.postPromoCode.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(CartItem);