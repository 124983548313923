import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/styles";

import PaymentMethodModal from "../../../Modals/CheckOut/PaymentMethod";
import { joinClasses } from "../../../../../utils";
import styles from "../../styles";

import { Field } from '../../../../../utils/forms';
import { detectCardName, getCardName } from '../../../../../utils/creditCards';

const SavedCards = ({
  classes,
  heading,
  user,
  handleCardChange,
  setNewCard,
  paymentFormValues,
  context,
  cardInvalid,
  isBulk,
  handleSetAsDefault
}) => {

  const [modalActive, setModalActive] = React.useState(false);
  const [containerExpanded, setContainerExpanded] = React.useState(false);

  const { CreditCards: cc } = user || {};

  const CreditCards = (cc || []).filter(({ isAuthorized }) => isAuthorized);

  const hasPaymentOptions = (CreditCards || []).length > 0

  const hasDefaultPaymentOptions = (CreditCards || []).some(({ IsDefault }) => IsDefault);

  const toggleContainer = () => {
    setContainerExpanded(!containerExpanded)
  }

  const openModal = e => {
    e.preventDefault();
    setModalActive(true);
  };

  const closeModal = () => {
    setModalActive(false);
  };

  const { cardId, defaultCard } = paymentFormValues || {};

  return (
    <>
      <div className={classes.savedCards}>
        <p className={joinClasses("h6", classes.subHeadline)}>{heading}</p>
        <FormControl>
          <Field
            name="cardId"
            normalize={value => parseInt(value)}
            onChange={(value) => handleCardChange(value.target.value)}
          >
            <RadioGroup
              aria-label="choose an address"
              row
              className={classes.radioMiddle}
            >
              {hasDefaultPaymentOptions &&
                <p
                  className={joinClasses(
                    "eyebrow",
                    "color--light-gray",
                    classes.eyebrow, classes.clear
                  )}
                >
                  Default Card
                </p>
              }
              {(CreditCards || []).filter(({ IsDefault }) => IsDefault).map((item, index) =>
                <FormControlLabel
                  key={index}
                  value={item.CardId || null}
                  control={<Radio color="primary" />}
                  label={getCardName(classes, item)}
                  labelPlacement="end"
                />
              )}
              <br />
              {hasPaymentOptions &&
                <p
                  className={joinClasses(
                    "eyebrow",
                    "color--light-gray",
                    classes.eyebrow, classes.clear
                  )}
                >
                  Other Card
                </p>
              }
              <div className={joinClasses(classes.addressesContainer, !containerExpanded && classes.contracted)}>
                {(CreditCards || [])
                  .filter(({ IsDefault }) => !IsDefault)
                  .filter((_, idx) => containerExpanded ? true : idx < 3)
                  .map((item, index) =>
                    <FormControlLabel
                      key={index}
                      value={item.CardId || null}
                      control={<Radio color="primary" />}
                      label={getCardName(classes, item)}
                      labelPlacement="end"
                    />
                  )}
                {(CreditCards || []).filter(({ IsDefault }) => !IsDefault).length >= 4 && (
                  <div
                    className={joinClasses(classes.button, classes.center, containerExpanded ? null : classes.showMore)}
                    onClick={toggleContainer}
                    onKeyDown={toggleContainer}
                  >
                    {containerExpanded ? "Show Less" : `Show more ${(context || '').toLowerCase()} payments`}
                  </div>
                )}
              </div>
            </RadioGroup>
          </Field>
        </FormControl>
        <button type='button' onClick={e => openModal(e)} className={joinClasses(classes.button, classes.center, containerExpanded ? null : classes.showMore)}>
          {cardId === 'new' ? 'Edit' : 'Enter a new'} payment method
        </button>
        {cardId === 'new' &&
          <div>{detectCardName(classes, paymentFormValues)}</div>
        }
      </div>
      <PaymentMethodModal
        isOpen={modalActive}
        onClose={closeModal}
        setNewCard={setNewCard}
        cardInvalid={cardInvalid}
        isBulk={isBulk}
        defaultCard={defaultCard}
        handleSetAsDefault={handleSetAsDefault}
      />
    </>
  );
};

export default withStyles(styles)(SavedCards);
