import React, { useRef, useEffect } from 'react';
import PlusIcon from '../../Shared/Icons/Plus';
import PromoCodeModal from '../../../modules/Modals/PromoCode';
import { withStyles } from '@material-ui/styles';
import { joinClasses } from '../../../../utils';
import numeral from 'numeral';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Loader from '../../Shared/Loader';
import fetchDux from '../../../../store/reducers/fetch-dux'
import CartItem from './CartItem';
import SoldOutItem from './SoldOutItem';
import SoldOutMessage from './SoldOutMessage';
import Stickyfill from 'stickyfilljs';
import styles from './styles';

const formatMoney = p => numeral(p || 0).format('$0,0.00');

const getSalesTax = (wholesaleTax, exciseTax, caCRVTax, CODeliveryFee, salesTaxTotal) => {
  if (CODeliveryFee > 0) return salesTaxTotal - CODeliveryFee;
  if (caCRVTax > 0) return salesTaxTotal - caCRVTax;
  if (exciseTax > 0 || wholesaleTax > 0) return salesTaxTotal - wholesaleTax - exciseTax;
  return salesTaxTotal
}

const CartSummary = ({
  classes,
  className,
  results,
  deleteCartItem,
  deleteCartItemInProgress,
  inProgress,
  id,
  clearPostPromoCode
}) => {
  const {
    cart,
    user,
    postPromoCodeResult
  } = JSON.parse(results || '{}');

  const {
    shoppingCartItems,
    shoppingCartItemsDeleted: soldOutItems,
    totals,
    promoCode,
    promoDetails,
    SetCoRetailTax,
    shippingAddress,
    caCRVTax,
    wholesaleTax,
    exciseTax,
    CODeliveryFee,
    salesTaxTotal,
    brandingId,
    promoInvalidReason,
  } = cart || {};

  const { stateId } = shippingAddress || {};
  const clubMembership = (shoppingCartItems || []).find(({ clubContinuityTypeId }) => clubContinuityTypeId > 0);
  const { clubName, clubDescription } = clubMembership || {};

  const {
    name: promoName
  } = promoDetails || {};

  const {
    totalAmountDue,
    subTotal,
    freight,
    totalMarkdown
  } = totals || {};

  const { userId } = user || {};

  const hasUser = !!userId;

  const [modalActive, setModalActive] = React.useState(false);
  const [hover, setHover] = React.useState(false);

  const reviewClass = (id === 'review') ? classes.showForReview : '';

  const stickyRef = useRef(null);

  const hideTaxAndFreight = id === 'shipping';

  const openModal = e => {
    e.preventDefault();
    setModalActive(true);
  };

  const closeModal = (code) => {
    setModalActive(false);
  };

  const getAffiliation = (id) => {
    if (id === 1) {
      return "Silver Oak";
    } else if (id === 2) {
      return "Twomey";
    }
  }

  const handleRemoveItem = item => {
    clearPostPromoCode();
    deleteCartItem({ shoppingCartItemId: item.shoppingCartItemId });
    if (window && window.dataLayer) {

      const itemCategories = (item.categories || []).map(({ productCategoryName }, index) => ({
        [`item_category${index + 1}`]: productCategoryName
      }));

      const gaEcomm = {
        'event': 'remove_from_cart',
        'ecommerce': {
          'affiliation': getAffiliation(brandingId),
          'currency': 'USD',
          'value': item.unitPrice ?? 0,
          'items': [
            {
              item_id: item.kitId ?? '',
              item_name: item.description ?? '',
              affiliation: getAffiliation(item.brandingId),
              currency: "USD",
              item_brand: item.brandingName ?? '',
              ...(itemCategories.length > 0 && itemCategories.reduce((acc, item) => ({ ...acc, ...item }))),
              discount: item.discount ?? 0,
              price: item.unitPrice ?? 0,
              quantity: item.qty ?? 0,
            }
          ]
        }

      }

      window.dataLayer.push(gaEcomm);
    }
  }

  const handleAddItem = (item, oldQty, newQty) => {
    if (window && window.dataLayer) {

      const itemCategories = (item.categories || []).map(({ productCategoryName }, index) => ({
        [`item_category${index + 1}`]: productCategoryName || ''
      }));

      const gaEcomm = {
        'event': newQty > oldQty ? 'add_to_cart' : 'remove_from_cart',
        'ecommerce': {
          'affiliation': getAffiliation(brandingId),
          'currency': 'USD',
          'value': item.unitPrice ?? 0,
          'items': [
            {
              item_id: item.kitId ?? '',
              item_name: item.description ?? '',
              affiliation: getAffiliation(item.brandingId),
              currency: "USD",
              item_brand: item.brandingName ?? '',
              ...(itemCategories.length > 0 && itemCategories.reduce((acc, item) => ({ ...acc, ...item }))),
              discount: item.discount ?? 0,
              price: item.unitPrice ?? 0,
              quantity: newQty - oldQty,
            }
          ]
        }
      }

      window.dataLayer.push(gaEcomm);
    }
  };

  useEffect(() => {
    Stickyfill.add([stickyRef.current]);
  });

  const {
    valid
  } = postPromoCodeResult || {};

  return (
    <div className={joinClasses(classes.container, className, reviewClass)} ref={stickyRef}>
      {
        clubMembership ?
          (
            <>
              <p className={joinClasses('h4', classes.headline)}>
                {`${clubName} Membership`}
              </p>
              <div
                className={joinClasses('h6', classes.headline)}
                dangerouslySetInnerHTML={(() => ({ __html: clubDescription }))()}
              />
            </>
          )
          :
          <>
            {!inProgress && <p className={joinClasses('h4', classes.headline)}>Your Cart</p>}
            {!inProgress && <SoldOutMessage classes={classes} soldOutItems={soldOutItems || []} />}
            <table className={classes.table}>
              {!inProgress && (
                <>
                  <thead>
                    <tr>
                      <th className='eyebrow color--light-gray'>Item</th>
                      <th style={{ paddingLeft: '2rem' }} className='eyebrow color--light-gray'>QTY</th>
                      <th style={{ paddingLeft: '2rem' }} className='eyebrow color--light-gray'>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <SoldOutItem
                      classes={classes}
                      soldOutItems={soldOutItems || []}
                    />
                    <CartItem
                      classes={classes}
                      shoppingCartItems={shoppingCartItems || []}
                      handleRemoveItem={handleRemoveItem}
                      handleAddItem={handleAddItem}
                      deleteCartItemInProgress={deleteCartItemInProgress}
                    />
                  </tbody>
                </>
              )}
              {inProgress && (
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'center', verticalAlign: 'middle', height: '150px', colSpan: '3' }}>
                      <Loader />
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </>
      }
      {
        !inProgress &&
        <div className={classes.cartTotal}>
          <ul>
            {!clubMembership &&
              <li>
                <a
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  onClick={e => openModal(e)}
                  onKeyPress={e => openModal(e)}
                  className={classes.promoCode}
                >
                  <label className='eyebrow color--light-gray'>Promo Code</label>
                  <p>{promoCode}</p>
                  <PlusIcon
                    reverse={hover}
                    className={classes.plusIcon}
                    color='primary'
                  />
                </a>
              </li>
            }

            <li style={{ borderStyle: 'none', paddingTop: '0px' }}>
              <a
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={e => openModal(e)}
                onKeyPress={e => openModal(e)}
                className={classes.promoName}
              >
                {promoCode && <label className='eyebrow color--light-gray'>Promo Offer</label>}
                {promoCode && <p>{promoName || '-'}</p>}
              </a>
            </li>
            {promoCode && promoInvalidReason &&
              <li style={{ borderStyle: 'none', paddingTop: '0px', fontSize: 'x-small', color: 'red' }}>
                <div>{promoInvalidReason}</div>
              </li>
            }
            {valid && !promoDetails && !inProgress &&
              <li style={{ borderStyle: 'none', paddingTop: '0px', fontSize: 'x-small', color: 'red' }}>
                <div>Promo removed, shipping promo codes are not applicable to overnight shipping.</div>
              </li>
            }
            <li>
              <span className='eyebrow color--light-gray'>Subtotal</span>
              <span>{formatMoney(subTotal)}</span>
            </li>
            {!hideTaxAndFreight && (
              <li>
                <span className='eyebrow color--light-gray'>Shipping</span>
                <span>{formatMoney(freight)}</span>
              </li>
            )}
            {!hideTaxAndFreight && (
              <li>
                <span className='eyebrow color--light-gray'>Sales Tax</span>
                <span>{formatMoney(getSalesTax(
                  wholesaleTax,
                  exciseTax,
                  caCRVTax,
                  CODeliveryFee,
                  salesTaxTotal))}</span>
              </li>
            )}
            {!hideTaxAndFreight && exciseTax > 0 && (
              <li>
                <span className='eyebrow color--light-gray'>Excise Tax</span>
                <span>{formatMoney(exciseTax)}</span>
              </li>
            )}
            {!hideTaxAndFreight && wholesaleTax > 0 && (
              <li>
                <span className='eyebrow color--light-gray'>Wholesale Tax</span>
                <span>{formatMoney(wholesaleTax)}</span>
              </li>
            )}
            {!hideTaxAndFreight && caCRVTax > 0 && (
              <li>
                <span className='eyebrow color--light-gray'>CRV</span>
                <span>{formatMoney(caCRVTax)}</span>
              </li>
            )}
            {!hideTaxAndFreight && SetCoRetailTax && stateId === 7 && CODeliveryFee > 0 && (
              <li>
                <span className='eyebrow color--light-gray'>CO Retail Delivery Fee</span>
                <span>{formatMoney(CODeliveryFee)}</span>
              </li>
            )}
            {totalMarkdown > 0 && (
              <li style={{ color: 'red' }}>
                <span className='eyebrow color--light-gray'>Discount</span>
                <span>-{formatMoney(totalMarkdown)}</span>
              </li>
            )}
            <li>
              <span>Grand Total</span>
              <span>{formatMoney(hideTaxAndFreight ? (subTotal || 0) - (totalMarkdown || 0) : totalAmountDue)}</span>
            </li>
          </ul>
        </div>
      }



      <PromoCodeModal isOpen={modalActive} onClose={closeModal} />
    </div>
  );
};

const mapState = state => {
  const {
    error,
    inProgress,
    result: cart
  } = state.fetchReducer.getCart.toJS();

  const {
    result: user,
    inProgress: postLoginInProgress
  } = state.fetchReducer.postLogin.toJS();
  const {
    inProgress: deleteCartItemInProgress
  } = state.fetchReducer.deleteCartItem.toJS();
  const {
    result: postPromoCodeResult
  } = state.fetchReducer.postPromoCode.toJS();

  const userInProgress = postLoginInProgress;

  return {
    userInProgress,
    inProgress,
    deleteCartItemInProgress,
    results: JSON.stringify({
      user,
      cart,
      postPromoCodeResult
    })
  }
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      deleteCartItem: fetchDux.deleteCartItem.createAction,
      clearPostPromoCode: fetchDux.postPromoCode.clearAction,
      getCart: fetchDux.getCart.createAction
    },
    dispatch
  );

const StyledCartSummary = withStyles(styles)(CartSummary);
const CartWithHistory = withRouter(StyledCartSummary)

export default connect(mapState, mapDispatch)(CartWithHistory);
